<template>
    <v-container class="pa-0">
        <v-alert type="info" dense class="my-4">{{ $t('views.reports.layout_info') }}</v-alert>
        <ReportHeader
            :title="$t('views.reports.vouchers.title')"
            :description="$t('views.reports.vouchers.description')"
            icon="fas fa-ticket-alt"
        >
            <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
                <v-row>
                    <v-col>
                        <DateInput
                            :label="$t('views.reports.commons.form.from.label')"
                            :placeholder="$t('views.reports.commons.form.from.placeholder')"
                            :hint="$t('views.reports.commons.form.from.hint')"
                            v-model="params.from"
                            :outputFormat="$constants.getConstant('DATETIME_TZ_FORMAT')"
                        />
                    </v-col>
                    <v-col>
                        <DateInput
                            :label="$t('views.reports.commons.form.to.label')"
                            :placeholder="$t('views.reports.commons.form.to.placeholder')"
                            :hint="$t('views.reports.commons.form.to.hint')"
                            v-model="params.to"
                            :outputFormat="$constants.getConstant('DATETIME_TZ_FORMAT')"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <ReportGroupByCombobox
                            v-model="computedGroupBy"
                            :label="$t('views.reports.commons.form.group_by.label')"
                            :placeholder="$t('views.reports.commons.form.group_by.placeholder')"
                            :availableItems="groupByAvailableItems"
                            multiple small-chips
                            clearable hide-selected
                            deletable-chips persistent-hint
                            :max="3"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <!-- [TEMP][TODO] será retomado na FID-1068 -->
                    <!-- <v-col>
                        <v-select
                            :label="$t('views.reports.commons.form.sort_by.label')"
                            :placeholder="$t('views.reports.commons.form.sort_by.placeholder')"
                            :hint="$t('views.reports.commons.form.sort_by.hint')"
                            v-model="params.sort_by"
                            :items="sortSelectItems"
                        />
                    </v-col> -->
                    <v-col>
                        <v-checkbox
                            :label="$t('views.reports.commons.form.count.label')"
                            v-model="computedCount"
                        />
                    </v-col>
                </v-row>

                <v-btn
                    color="success"
                    type="submit"
                    :loading="loading"
                    :disabled="!isFormValid"
                >{{ $t('actions.confirm') }}</v-btn>
            </v-form>
        </ReportHeader>

        <ReportContent
            :headers="computedHeaders"
            :reportResponse="reportResponse"
            :loading="loading"
            :pagination.sync="pagination"
            :hasError="hasError"
            :model="Voucher"
            :count="computedLastCount"
            @export="exportReport"
        >
            <template #item="{ item }">
                <tr v-if="!computedLastCount">
                    <td>{{ item.customer_document }}</td>
                    <td>{{ item.created_by_text }}</td>
                    <td>{{ item.created_at | datetime }}</td>
                    <td>{{ item.withdrawn_at | datetime }}</td> 
                    <td>{{ item.withdrawal_company_name }}</td>
                    <td>{{ item.prize_name }}</td>
                    <!-- <td>
                        <router-link :to="{ name: 'customers.details', params: { id: item.id } }"
                        >{{ $t('views.reports.vouchers.content.go_to_customer') }}</router-link>
                    </td> -->
                </tr>
            </template>
        </ReportContent>

    </v-container>
</template>
<script>
import HasErrorHandlerMixin  from '@/mixins/HasErrorHandlerMixin'
import DateInput             from '@/shared/components/inputs/DateInput'
import ReportHeader          from '../commons/ReportHeader'
import ReportContent         from '../commons/ReportContent'
import VoucherRepository     from '@/repositories/VoucherRepository'
import ReportResponse        from '@/models/ReportResponse'
import Voucher               from '@/models/Voucher'
import GroupByConfig         from '@/models/GroupByConfig'
import ReportGroupByCombobox from '@/components/inputs/ReportGroupByCombobox'

export default {
    mixins: [ HasErrorHandlerMixin ],
    components: { DateInput, ReportHeader, ReportContent, ReportGroupByCombobox },
    data: vm => ({
        Voucher,
        params: {
            from     : vm.$route.query.from       || '',
            to       : vm.$route.query.to         || '',
            group_by : vm.$route.query.group_by   || '',
            count    : vm.$route.query.count      || 'true',         // Por padrão mostra apenas as contagens
            // sort_by  : vm.$route.query.sort_by    || 'created_at',   // Por padrão mostra por data de criação
            filter_by: vm.$route.query.filter_by  || 'created_at',   // Por padrão filtra por data de criação
        },
        lastParams: {
            from    : undefined,
            to      : undefined,
            group_by: undefined,
            count   : undefined,
        },

        groupByAvailableItems: [
            new GroupByConfig({ value: 'field:prize.id',              label: vm.$t('views.reports.vouchers.form.group_by.prize') }),
            new GroupByConfig({ value: 'field:withdrawal_company.id', label: vm.$t('views.reports.vouchers.form.group_by.company') }),
            new GroupByConfig({ value: 'field:created_by.type',       label: vm.$t('views.reports.vouchers.form.group_by.created_by') }),
            new GroupByConfig({ value: 'time:withdrawn_at:1:HOUR',    label: vm.$t('views.reports.vouchers.form.group_by.withdrawn_at_hour') }),
            new GroupByConfig({ value: 'time:withdrawn_at:1:DAY',     label: vm.$t('views.reports.vouchers.form.group_by.withdrawn_at_day') }),
            new GroupByConfig({ value: 'time:withdrawn_at:1:WEEK',    label: vm.$t('views.reports.vouchers.form.group_by.withdrawn_at_week') }),
            new GroupByConfig({ value: 'time:withdrawn_at:1:MONTH',   label: vm.$t('views.reports.vouchers.form.group_by.withdrawn_at_month') }),
            new GroupByConfig({ value: 'time:withdrawn_at:1:YEAR',    label: vm.$t('views.reports.vouchers.form.group_by.withdrawn_at_year') }),
            new GroupByConfig({ value: 'time:created_at:1:HOUR',      label: vm.$t('views.reports.vouchers.form.group_by.created_at_hour') }),
            new GroupByConfig({ value: 'time:created_at:1:DAY',       label: vm.$t('views.reports.vouchers.form.group_by.created_at_day') }),
            new GroupByConfig({ value: 'time:created_at:1:WEEK',      label: vm.$t('views.reports.vouchers.form.group_by.created_at_week') }),
            new GroupByConfig({ value: 'time:created_at:1:MONTH',     label: vm.$t('views.reports.vouchers.form.group_by.created_at_month') }),
            new GroupByConfig({ value: 'time:created_at:1:YEAR',      label: vm.$t('views.reports.vouchers.form.group_by.created_at_year') }),
        ],

        loading       : false,
        hasError      : false,
        isFormValid   : false,
        reportResponse: new ReportResponse,
        pagination    : {
            page    : vm.$route.query.page || 1,
            per_page: vm.$constants.getConstant('REPORT_DEFAULT_PER_PAGE'),
        },
        // sortSelectItems: [
        //     { value: 'created_at',   text: vm.$t('views.reports.commons.form.sort_by.created_at') },
        //     { value: 'withdrawn_at', text: vm.$t('views.reports.vouchers.form.sort_by.withdrawn_at') },
        // ],
    }),
    methods: {
        async submit() {
            this.pagination.page = 1
            await this.fetchData()
        },
        async fetchData() {
            this.loading      = true
            this.hasError     = false
            this.responseData = {}

            if (this.$refs.form && !this.$refs.form.validate()) {
                this.loading  = false
                return
            }

            // Atualiza parâmetros da busca na query para poder voltar com o navegador
            this.$router.push({ query: this.computedQuery })

            // Logo em seguida faz request para listar
            this.reportResponse = await VoucherRepository.fetchReport(this.computedPagination, this.params)
                .catch(this.preErrorHandler)

            this.lastParams = this.$lodash.cloneDeep(this.params)
            this.loading = false
        },
        async exportReport() {
            // [TEMP][FID-463] Implementação provisória, será melhorada na FID-463
            this.loading  = true
            this.hasError = false

            // Valida parâmetros atuais
            if (this.$refs.form && !this.$refs.form.validate()) {
                this.loading = false
                return
            }

            // Logo em seguida faz request para exportar
            await VoucherRepository.fetchReport(this.computedPagination, { ...this.params, export: true, count: false })
                .catch(this.preErrorHandler)

            this.lastParams = this.$lodash.cloneDeep(this.params)
            this.loading    = false
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
    computed: {
        computedQuery() {
            return {
                ...this.computedPagination,
                ...this.params,
            }
        },
        computedGroupBy: {
            get() {
                return (this.params.group_by || '')
                    .split(';')
                    .map(i => this.groupByAvailableItems.find(a => a.value == i))
                    .filter(i => !!i)
            },
            set(v) {
                this.params.group_by = v.map(i => i.value).join(';')
            },
        },
        computedPagination() {
            if (this.computedCount) {
                return {
                    ...this.$lodash.omit(this.pagination, ['page']),
                    per_page: this.$constants.getConstant('PAGINATION_MAX_PER_PAGE'),
                }
            }

            return this.pagination
        },
        computedCount: {
            get() {
                return (this.params.count || '').includes('true')
            },
            set(v) {
                this.params.count = v ? 'true' : 'false'
            },
        },
        computedLastCount() {
            return (this.lastParams.count || '').includes('true')
        },
        computedHeaders() {
            return this.computedLastCount ? 
            [
                { text: this.$t('views.reports.commons.header.label') },
                { text: this.$t('views.reports.commons.header.count'), align: 'right' },
            ] : [
                { text: this.$t('views.reports.vouchers.content.customer')     },
                { text: this.$t('views.reports.vouchers.content.created_by')     },
                { text: this.$t('views.reports.vouchers.content.created_at')   },
                { text: this.$t('views.reports.vouchers.content.withdrawn_at') },
                { text: this.$t('views.reports.vouchers.content.prize_name')   },
                { text: this.$t('views.reports.vouchers.content.company_name') },
            ]
        },
    },
    watch: {
        pagination: {
            deep     : true,
            immediate: true,
            handler(newPagination) {
                this.fetchData()
            },
        },
    },
}
</script>